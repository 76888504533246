@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,600;0,700;0,800;1,400&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    outline: none;
}

body {
    overflow-y: scroll;
}

hr {
    border-top-width: 2px;
}

i {
    display: block;
}

.button {
    @apply whitespace-no-wrap bg-blue rounded text-white p-1 px-4 font-bold leading-loose cursor-pointer border-blue border-2 select-none inline-block;
    transition: opacity .3s ease-out, transform .3s ease-out;
}

.button-white {
    @apply button bg-white text-blue border-white;
}

.button:hover, .button-white:hover {
    opacity: .9;
    transform: translateY(-1px);
}

.button.disabled:hover, .button-white.disabled:hover {
    opacity: 1;
    transform: none;
    cursor: auto;
}

a {
    @apply text-blue;
    transition: opacity .3s ease-out, transform .3s ease-out, color .3s ease-out;
}

a:hover {
    transform: translateY(-1px);
}

.rotate-0 {
    transition: all .2s;
    transform: rotate(0deg);
}

.rotate-90 {
    transition: all .2s;
    transform: rotate(90deg);
}

.rotate-180 {
    transition: all .2s;
    transform: rotate(180deg);
}

.rotate-270 {
    transition: all .2s;
    transform: rotate(270deg);
}

.highlighted {
    background-image: url("./assets/highlight.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-position-x: -12px;
    background-position-y: -46px;
}

.content-wrapper {
    @apply max-w-6xl m-auto px-8 my-4;
}

@screen md {
    .content-wrapper {
        @apply px-12;
    }
}

ul {
    @apply list-inside my-4;
    margin-left: 16px;
}

ul li::before {
    content: "\2022";
    color: #5186d9;
    font-weight: bold;
    display: inline-block;
    width: 16px;
    margin-left: -16px;
}

br {
    display: block;
    content: "";
    margin-top: 16px;
}

label {
    @apply uppercase font-semibold text-xs mb-1 select-none text-left;
}

input, textarea {
    @apply mb-4 border-2 border-gray-bright rounded bg-gray-bright py-2 px-4;
    transition: 0.4s border-color, 0.4s background-color;
    -webkit-appearance: none;
}

input:hover, textarea:hover {
    @apply border-blue-bright;
}

input:focus, textarea:focus {
    @apply border-blue bg-blue-bright;
}

.page-header {
    @apply my-4;
}

@screen md {
    .page-header {
        @apply my-16;
    }
}

.page-title {
    @apply text-center text-2xl bg-blue-bright text-blue;
}

@screen md {
    .page-title {
        @apply text-3xl;
    }
}

.page-subtitle {
    @apply text-center text-blue text-lg max-w-3xl m-auto mt-8;
}

@screen md {
    .page-subtitle {
        @apply text-2xl px-8;
    }
}

.pieces-background {
    background-image: url("assets/pieces.svg");
    background-size: auto;
    background-repeat: no-repeat;
    background-position: bottom right;
}
